import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Turkish Get Ups & Wall Walks`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`Partner WOD`}</strong></p>
    <p>{`1 lap run w/sandbag (both partners run the lap switching off with the
sandbag as needed)`}</p>
    <p>{`30 sandbag clean & press`}</p>
    <p>{`30 sandbag front squats`}</p>
    <p>{`30 partner burpees`}</p>
    <p>{`300ft bear crawl`}</p>
    <p>{`300ft oh walking lunges (45/25)`}</p>
    <p>{`15 wall walks`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      